import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  Box,
  Chip,
  Grid,
  IconButton,
  Typography,
  Popover,
  Dialog,
  DialogContent,
  Tooltip,
  tooltipClasses,
  colors,
} from "@mui/material";
import { styled } from "@mui/system";
import HighlightOffOutlinedIcon from "@mui/icons-material/HighlightOffOutlined";

import { theme } from "../App";
import LoadingBgImage from "../../components/loadingBgImage/LoadingBgImage";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import ReactEcharts from "echarts-for-react";
import TopStoreAction from "../../stores/topStoresReport/TopStoreAction";
import TopLabelAction from "../../stores/topLabelReport/TopLabelAction";
import TopTerritoriesAction from "../../stores/TopTerritories/TopTerritoriesAction";
import * as echarts from "echarts";
import * as d3 from "d3-geo";
import worldJson from "./worldJson.json"; // Import the JSON file
import TopDevicesAction from "../../stores/topDevicesRport/TopDevicesAction";
import TopTrackAction from "../../stores/topTrackReport/TopTrackAction";
import ErrorBoundary from "../../components/ErrorBoundary";
import ReportContainer from "./ReportContainer";
import CalendarPopover from "./DashboardFilters";
import Rettrive from "../releases/Rettrive";
import { getRandomColor } from "../reports/ReportsReusedFunctions";
const mapStateToProps = (state) => ({
  topStoreRequesting: selectRequesting(state, [
    TopStoreAction.REQUEST_TOPSTORE,
  ]),
  topLabelRequesting: selectRequesting(state, [
    TopLabelAction.REQUEST_TOPLABEL,
  ]),
  topTerritoriesRequesting: selectRequesting(state, [
    TopTerritoriesAction.REQUEST_TOP_TERRITORIES,
  ]),
  audioStreamsRequesting: selectRequesting(state, [
    TopStoreAction.REQUEST_AUDIO_STREAMS,
  ]),
  topDevicesRequesting: selectRequesting(state, [
    TopDevicesAction.REQUEST_TOPDEVICES,
  ]),
  topTracksRequesting: selectRequesting(state, [
    TopTrackAction.REQUEST_TOPTRACK_WITH_DATE_FILTER,
  ]),
  topStore: state.topStore.topStore || [],
  pagination: state.topLabel.topLabel || [],
  topLabel: state.topLabel.topLabel || [],
  top_territories_report:
    state.top_territories_report.top_territories_report || [],
  audioStreams: state.audioStreams.audioStreams || [],
  top_device_type_report:
    state.top_device_type_report.top_device_type_report || [],
  topTracks: state.topTrack.topTrack || [],
  topTrack_with_date_filter:
    state.topTrack_with_date_filter.topTrack_with_date_filter || [],
});

export const reports = [
  { report_id: "top_track_report", Name: "Tracks" },
  { report_id: "top_retailers_report", Name: "Retailers" },
  { report_id: "top_label_report", Name: "Labels" },
  { report_id: "top_territory_report", Name: "Territories" },
  { report_id: "top_device_report", Name: "Device Type" },
  { report_id: "top_audiostreams_report", Name: "Audio Streams" },
];
function returnReportname(reportId) {
  if (reportId === "top_audiostreams_report") return "Audio Streams";
  else if (reportId === "top_device_report") return "Device Type";
  else if (reportId === "top_track_report") return "Tracks";
  else if (reportId === "top_retailers_report") return "Retailers";
  else if (reportId === "top_label_report") return "Labels";
  else return "Territory";
}
function DynamicReport(props) {
  const {
    topStoreRequesting,
    topLabelRequesting,
    topTerritoriesRequesting,
    topTracksRequesting,
    topDevicesRequesting,
    audioStreamsRequesting,
  } = props;

  const today = new Date();
  const formattedDate = today.toISOString().split("T")[0];
  const thirtyDaysAgo = new Date(today);
  thirtyDaysAgo.setDate(today.getDate() - 30);
  const formattedThirtyDaysAgoDate = thirtyDaysAgo.toISOString().split("T")[0];
  const [StartDate, setStartDate] = useState(formattedThirtyDaysAgoDate);
  const [EndDate, setEndDate] = useState(formattedDate);
  const [trackSelected, setTrackSelected] = useState(null);
  const [labelSelected, setLabelSelected] = useState(null);
  const [retailerSelected, setRetailerSelected] = useState(null);
  const [countrySelected, setCountrySelected] = useState(null);
  const [devicesSelected, setDevicesSelected] = useState(null);
  const [detailReportOpen, setDetailReportOpen] = useState();
  const [detailReportId, setDetailReportId] = useState();
  // function isFilterApplied() {
  //   return trackSelected || labelSelected || retailerSelected ? true : false;
  // }
  useEffect(() => {
    function returnTrackFilter() {
      return `["track_id","must","","match","${trackSelected}"]`;
    }

    function returnRetailerFilter() {
      return `["retailers.${retailerSelected}", "must", "", "exists", ""]`;
    }
    function returnLabelFilter() {
      return `["label", "must", "", "match", "${labelSelected}"]`;
    }

    function returnCountryFilter() {
      return `["territories.${countrySelected}", "must", "", "exists", ""]`;
    }

    function returnDeviceFilter() {
      return `[]`;
    }

    function returnDateFilter() {
      return `["current_date","must","lte","range","${EndDate}"],["current_date","must","gte","range","${StartDate}"]`;
    }

    const topTracksFilters = [];
    const topRetailersFilters = [];
    const topLabelsFilters = [];
    const topTerritoryFilters = [];
    const topDevicesFilters = [];
    const audioStreamsFilters = [];

    if (trackSelected) {
      topLabelsFilters.push(returnTrackFilter());
      topTerritoryFilters.push(returnTrackFilter());
      topRetailersFilters.push(returnTrackFilter());
      topDevicesFilters.push(returnTrackFilter());
      audioStreamsFilters.push(returnTrackFilter());
    }
    if (retailerSelected) {
      topTracksFilters.push(returnRetailerFilter());
      topRetailersFilters.push(returnRetailerFilter());
      topTerritoryFilters.push(returnRetailerFilter());
      topLabelsFilters.push(returnRetailerFilter());
      topDevicesFilters.push(returnRetailerFilter());
      audioStreamsFilters.push(returnRetailerFilter());
    }
    if (labelSelected) {
      topTracksFilters.push(returnLabelFilter());
      topTerritoryFilters.push(returnLabelFilter());
      topRetailersFilters.push(returnLabelFilter());
      topDevicesFilters.push(returnLabelFilter());
      audioStreamsFilters.push(returnLabelFilter());
      topLabelsFilters.push(returnLabelFilter());
    }
    // if (countrySelected) {
    //   topTracksFilters.push(returnCountryFilter());
    //   topRetailersFilters.push(returnCountryFilter());
    //   topLabelsFilters.push(returnCountryFilter());
    //   topDevicesFilters.push(returnCountryFilter());
    //   audioStreamsFilters.push(returnCountryFilter());
    // }
    // if (devicesSelected) {
    //   topTracksFilters.push(returnDeviceFilter());
    //   topRetailersFilters.push(returnDeviceFilter());
    //   topLabelsFilters.push(returnDeviceFilter());
    //   topTerritoryFilters.push(returnDeviceFilter());
    //   audioStreamsFilters.push(returnDeviceFilter());
    // }
    if (StartDate && EndDate) {
      topTracksFilters.push(returnDateFilter());
      topRetailersFilters.push(returnDateFilter());
      topLabelsFilters.push(returnDateFilter());
      topTerritoryFilters.push(returnDateFilter());
      audioStreamsFilters.push(returnDateFilter());
      topDevicesFilters.push(returnDateFilter());
    }

    const topTrackParams = {
      current_page: "dashboard",
      filters: `[${topTracksFilters}]`,
      size: 1000,
    };
    fetchTopTrack(topTrackParams);

    const topRetailerParams = {
      current_page: "dashboard",
      filters: `[${topRetailersFilters}]`,
    };

    fetchTopStores(topRetailerParams);

    const topLabelParams = {
      current_page: "dashboard",
      filters: `[${topLabelsFilters}]`,
    };
    fetchTopLabel(topLabelParams);

    const topTerritoryParams = {
      current_page: "dashboard",
      filters: `[${topTerritoryFilters}]`,
    };
    fetchTopTerritories(topTerritoryParams);

    const topDevicesParams = {
      current_page: "dashboard",
      filters: `[${topDevicesFilters}]`,
      count: 5,
    };
    fetchTopDevices(topDevicesParams);

    const audioStreamparams = {
      current_page: "dashboard",
      filters: `[${topDevicesFilters}]`,
    };
    fetchAudioStreams(audioStreamparams);
  }, [
    trackSelected,
    retailerSelected,
    labelSelected,
    countrySelected,
    devicesSelected,
    StartDate,
    EndDate,
  ]);

  function fetchTopStores(params) {
    props.dispatch(TopStoreAction.requestTopStore(params));
  }
  function fetchTopLabel(params) {
    props.dispatch(TopLabelAction.requestTopLabel(params));
  }

  function fetchTopTerritories(params) {
    props.dispatch(TopTerritoriesAction.requestTopTerritories(params));
  }
  function fetchTopDevices(params) {
    props.dispatch(TopDevicesAction.requestTopDevices(params));
  }

  const fetchAudioStreams = (params) => {
    props.dispatch(TopStoreAction.requestAudioStreams(params));
  };

  const TopDevicesrows = [];

  props?.top_device_type_report?.top_device_type_report?.map((item, index) => {
    TopDevicesrows.push({
      sl_no: ++index,
      id: ++index,
      count: item.count,
      name: item.key,
      percent: item.percentage,
    });
  });

  function fetchTopTrack(param) {
    props.dispatch(TopTrackAction.requestTopTrackWithDateFilter(param));
  }
  const formatValue = (value) => {
    if (value >= 1e6) {
      return (value / 1e6).toFixed(2) + "M";
    } else if (value >= 1e3) {
      return (value / 1e3).toFixed(2) + "K";
    }
    return value;
  };
  const TopTerritoriesrows = [];

  props?.top_territories_report?.top_territories?.map((item, index) => {
    TopTerritoriesrows.push({
      sl_no: ++index,
      id: ++index,
      count: item.count,

      percent: item.percentage.toFixed(2),
      country_name: item.key ? item.key.country_name : "",
      country_code: item.key ? item.key.country_code : "",
    });
  });

  const topLabelRows = [];

  if (props.topLabel) {
    props.topLabel.top_labels &&
      props.topLabel.top_labels.length > 0 &&
      props.topLabel.top_labels.map((item, index) => {
        topLabelRows.push({
          sl_no: index + 1,
          id: item.key,
          label: item?.label_name,
          stream_percentage: item?.stream_percentage,
          audio_streams: item?.count,
        });
      });
  }

  const topStoreRows = [];

  if (props.topStore && props.topStore.top_stores_report) {
    props.topStore.top_stores_report &&
      props.topStore.top_stores_report.length > 0 &&
      props.topStore.top_stores_report.map((item, index) => {
        topStoreRows.push({
          sl_no: ++index,
          id: ++index,
          count: item.count,
          stores: item.key,
          percent: item.percentage.toFixed(2),
        });
      });
  }

  const audio_streams = [];

  if (props.audioStreams && props.audioStreams.audio_streams) {
    props.audioStreams.audio_streams.length > 0 &&
      props.audioStreams.audio_streams.map((item, index) => {
        audio_streams.push({
          sl_no: ++index,
          id: ++index,
          count: item.count,
          date: item.key,
        });
      });
  }
  const LabelReport = () => {
    const nameLabel = topLabelRows.map((item) => item.labels);
    const seriesData = topLabelRows.map((item, index) => {
      const itemColor =
        index < theme.palette.dashboardCharts.length
          ? theme.palette.dashboardCharts[index]
          : getRandomColor();
      return {
        name: item.label,
        value: item.audio_streams,
        percentage: item.stream_percentage,
        id: item.id,
        itemStyle: {
          color: itemColor,
        },
      };
    });

    const getOptions = () => {
      return {
        tooltip: {
          trigger: "item",
          formatter: (params) => {
            let val = params.value;
            let formattedVal = formatValue(val);
            const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params.color};border-radius:50%;"></span>`;
            return ` ${colorSpan} ${params.name}: ${formattedVal} (${params.data.percentage}%)`;
          },
        },
        grid: {
          top: "10%",
          left: "0%",
          right: "5%",
          bottom: "5%",
        },
        legend: {
          icon: "circle",
          top: 0,
          type: "scroll",

          itemStyle: {
            borderColor: "#6c757d",
            borderWidth: 1,
          },
        },
        series: [
          {
            type: "pie",
            radius: ["40%", "70%"],
            avoidLabelOverlap: false,
            emphasis: {
              focus: "series",
            },
            labelLine: {
              show: true,
            },
            label: {
              show: true,
              formatter: (params) => {
                let formattedValue = formatValue(params.value);
                let percentage = params.percent.toFixed(2);
                return `${formattedValue}\n(${percentage}%)`;
              },
            },
            data: seriesData,
          },
        ],
      };
    };

    const onChartClick = (params) => {
      setLabelSelected(params.data.id);
    };
    const onEvents = {
      // click: isFilterApplied() ? null : onChartClick,
      click: onChartClick,
    };
    return (
      <Box sx={{ height: "100%" }}>
        <ReactEcharts
          style={{
            height: "100%",
            width: "90%",
          }}
          option={getOptions()}
          onEvents={onEvents}
        />
      </Box>
    );
  };

  const RetailerReport = () => {
    const nameLabel = topStoreRows.map((item) => item.stores);
    const seriesData = topStoreRows.map((item, index) => {
      const itemColor =
        index < theme.palette.dashboardCharts.length
          ? theme.palette.dashboardCharts[index]
          : getRandomColor();
      return {
        value: item.count,
        percentage: item.percent,
        itemStyle: {
          color: itemColor,
        },
      };
    });
    const onChartClick = (params) => {
      setRetailerSelected(params.name);
    };

    const getOptions = () => {
      return {
        tooltip: {
          trigger: "axis", // or 'item' if you want tooltips for individual bars
          axisPointer: {
            type: "shadow", // This option adds a shadow to the xAxis or yAxis pointer for better visualization
          },
          confine: true,
          formatter: (params) => {
            let val = params[0].value;
            let formattedVal = formatValue(val);
            const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params[0].color};border-radius:50%;"></span>`;
            return `${colorSpan}${params[0].name}:<br>${formattedVal} (${params[0].data.percentage}%)`;
          },
        },

        grid: {
          top: "5%",
          left: "10%",
          right: "5%",
          bottom: "15 %",
        },

        dataZoom: [
          {
            type: "inside",
            filterMode: "weakFilter",
          },
        ],
        xAxis: [
          {
            type: "category",
            data: nameLabel,
            axisLabel: {
              rotate: 35, // Rotate labels by 45 degrees
              hideOverlap: true,
              overflow: "break", // Break long labels
              width: 50, // Set a maximum width for the labels
            },
            name: detailReportOpen ? "Retailer" : "",
            nameLocation: "bottom",
            nameTextStyle: {
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontFamily: "BookAntiqua",
              verticalAlign: "left",
              lineHeight: 25,
              fontSize: 20,
              padding: [75, 0, 0, 0],
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: (value) => formatValue(value),
              hideOverlap: true,
            },
            axisPointer: {
              triggerTooltip: true,
            },
            name: detailReportOpen ? "Sum Of Quantity" : "",
            nameLocation: "middle",
            nameTextStyle: {
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontFamily: "BookAntiqua",
              fontSize: 20,
              lineHeight: 0,
              padding: [0, 0, 70, 0],
            },
          },
        ],
        series: [
          {
            data: seriesData,
            type: "bar",
            itemStyle: {
              borderRadius: [12, 12, 0, 0],
            },
            barWidth: 15,
          },
        ],
      };
    };

    const onEvents = {
      // click: isFilterApplied() ? null : onChartClick,
      click: onChartClick,
      mouseup: (e) => {},
      mousedown: (e) => {},
    };

    return (
      <Box sx={{ height: "100%" }}>
        <ReactEcharts
          style={{
            height: "100%",
            width: "90%",
          }}
          option={getOptions()}
          onEvents={onEvents}
        />
      </Box>
    );
  };

  const AudioStreamsChart = () => {
    const [chartInstance, setChartInstance] = useState(null);

    const getOption = () => {
      const nameLabel = audio_streams.map((item) => item.date);
      const originalData = audio_streams.map((item) => item.count);

      return {
        tooltip: {
          trigger: "axis",
          formatter: (params) => {
            let val = params[0].value;
            let formattedVal = formatValue(val);
            const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${theme.palette.primary.main};border-radius:50%;"></span>`;
            return `${params[0].name}:<br>${colorSpan}  ${formattedVal} `;
          },
        },
        grid: {
          top: "5%",
          left: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            data: nameLabel,
            name: detailReportOpen ? "Day" : "",
            nameLocation: "bottom",
            nameTextStyle: {
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontFamily: "BookAntiqua",
              verticalAlign: "left",
              lineHeight: 25,
              fontSize: 20,
              padding: [30, 0, 0, 0],
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            axisLabel: {
              formatter: (value) => formatValue(value),
              hideOverlap: true,
            },
            axisPointer: {
              triggerTooltip: true,
            },
            name: detailReportOpen ? "Sum Of Quantity" : "",
            nameLocation: "middle",
            nameTextStyle: {
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontFamily: "BookAntiqua",
              fontSize: 20,
              lineHeight: 0,
              padding: [0, 0, 50, 0],
            },
          },
        ],
        series: [
          {
            data: originalData,
            type: "line",
            color: theme.palette.primary.main,
          },
        ],
        brush: {
          toolbox: ["lineX", "clear"],
          xAxisIndex: 0,
        },
        toolbox: {
          feature: {
            brush: {
              type: ["lineX", "clear"],
            },
          },
        },
      };
    };

    useEffect(() => {
      if (chartInstance) {
        let isBrushing = false;
        let coordRange;

        const handleBrushSelected = (params) => {
          if (
            params.batch &&
            params.batch.length > 0 &&
            params.batch[0].areas &&
            params.batch[0].areas.length > 0
          ) {
            const selected = params.batch[0].areas[0];
            coordRange = selected.coordRange;
            isBrushing = true;
          }
        };
        const handleMouseUp = () => {
          if (isBrushing && coordRange) {
            const nameLabel = audio_streams.map((item) => item.date);
            setStartDate(nameLabel[coordRange[0]]);
            setEndDate(nameLabel[coordRange[1]]);
            isBrushing = false;
          }
        };

        chartInstance.on("brushSelected", handleBrushSelected);
        document.addEventListener("mouseup", handleMouseUp);

        return () => {
          chartInstance.off("brushSelected", handleBrushSelected);
          document.removeEventListener("mouseup", handleMouseUp);
        };
      }
    }, [chartInstance, audio_streams, setStartDate, setEndDate]);

    return (
      <Box sx={{ width: "100%", height: "100%" }}>
        <ReactEcharts
          option={getOption()}
          onChartReady={(chart) => setChartInstance(chart)}
          style={{ width: "100%", height: "100%" }}
        />
      </Box>
    );
  };

  const TerritoryReport = () => {
    const [error, setError] = useState(null);
    const [mapReady, setMapReady] = useState(false);
    const seriesData = TopTerritoriesrows.map((item) => ({
      name: item.country_name,
      value: item.count,
      percentage: item.percent,
      country_code: item?.country_code,
    }));
    const maxValue = Math.max(...seriesData.map((item) => item.value));
    const minValue = Math.min(...seriesData.map((item) => item.value));

    const formatDataView = (opt) => {
      const axisData = opt.series[0].data.map((item) => item.name);
      const series = opt.series[0].data.map((item) => formatValue(item.value));

      let table =
        '<table style="width:100%;text-align:center;border-collapse:collapse;"><thead><tr>' +
        '<th style="border:1px solid #ddd;padding:8px;">Top Territory</th>' +
        '<th style="border:1px solid #ddd;padding:8px;">Value</th>' +
        "</tr></thead><tbody>";

      for (let i = 0, l = axisData.length; i < l; i++) {
        table +=
          "<tr>" +
          '<td style="border:1px solid #ddd;padding:8px;">' +
          axisData[i] +
          "</td>" +
          '<td style="border:1px solid #ddd;padding:8px;">' +
          series[formatValue(i)] +
          "</td>" +
          "</tr>";
      }
      table += "</tbody></table>";
      return table;
    };

    const getOption = () => {
      try {
        const projection = d3.geoMercator();

        return {
          title: {
            left: "right",
          },
          tooltip: {
            trigger: "item",
            showDelay: 0,
            transitionDuration: 0.2,
            formatter: function (params) {
              let formattedValue = formatValue(params.value);
              return `${params.name}: ${formattedValue}`;
            },
          },
          visualMap: {
            left: "right",
            min: minValue,
            max: maxValue,
            inRange: {
              color: [
                "#969393",
                "#08c7c4",
                "#4175a8",
                "#38808e",
                "#c19c58",
                "#c67969",
                "#44a076",
              ],
            },
            text: ["High", "Low"],
            calculable: true,
          },
          toolbox: {
            show: true,
            left: "left",
            top: "top",
            feature: {
              dataView: {
                readOnly: false,
                optionToContent: function (opt) {
                  return formatDataView(opt);
                },
              },
              saveAsImage: {},
            },
          },
          series: [
            {
              name: "Top Territories",
              type: "map",
              map: "world",
              projection: {
                project: function (point) {
                  return projection(point);
                },
                unproject: function (point) {
                  return projection.invert(point);
                },
              },
              emphasis: {
                label: {
                  show: true,
                },
              },
              data: seriesData,
            },
          ],
        };
      } catch (e) {
        setError(`Error generating chart options: ${e.message}`);
        return {};
      }
    };

    useEffect(() => {
      echarts.registerMap("world", worldJson);
      setMapReady(true);
    }, []);

    const onChartClick = (params) => {};

    return (
      <Box style={{ width: "100%", height: "100%" }}>
        {error && <div style={{ color: "red" }}>{error}</div>}
        {mapReady ? (
          <ReactEcharts
            option={getOption()}
            style={{ width: "100%", height: "100%" }}
            onEvents={{ click: onChartClick }}
          />
        ) : null}
      </Box>
    );
  };

  const TopTrack = () => {
    const LightTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: "rgba(0, 0, 0, 1)",
        boxShadow: theme.shadows[8], // Adjust the shadow to make it more pronounced
        borderRadius: theme.shape.borderRadius, // Rounded corners
        padding: "12px 16px", // Padding for the card
        fontSize: 16,
        border: `1px solid ${theme.palette.divider}`, // Optional: border around the card
        // Additional card-like styles
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    }));
    const toptracksData = [];
    if (props.topTrack_with_date_filter?.Top_tracks_for_given_dates) {
      props.topTrack_with_date_filter?.Top_tracks_for_given_dates?.forEach(
        (track) => {
          toptracksData.push({
            id: track._id,
            name: track._source.title,
            views: track._source.views,
            url: track._source.release_id.thumbnail_url,
            release_id: track._source.release_id.id,
          });
        }
      );
    }
    const TooltipContent = styled("div")(({ theme }) => ({
      padding: "8px 12px",
      fontSize: 16,
      lineHeight: 1.5,
      textAlign: "center",
    }));
    const ImageTextContainer = styled("div")({
      width: "103px", // Fixed width for the container
      textAlign: "center", // Center the text
    });
    const ImageContainer = styled("div")({
      height: "103px",
      width: "103px",
      overflow: "hidden",
      img: {
        width: "100%",
        height: "auto",
      },
    });

    return (
      <Grid
        container
        sm={12}
        md={12}
        lg={12}
        sx={{
          width: "100%",
          height: "100%",
          padding: 1,
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
        rowGap={1}
        columnGap={1}
      >
        {toptracksData.map((item) => (
          <Grid
            key={item.id}
            item
            sx={{
              border: `1px solid ${theme.palette.info.main}`,
              borderRadius: 1,
              height: "fit-content",
              width: "max-content",
            }}
          >
            <Typography
              component="div"
              sx={{
                cursor: "pointer",
                padding: "5px",
                whiteSpace: "nowrap",
                wordWrap: "break-word",
                overflow: "hidden",
                textOverflow: "ellipsis",
                backgroundColor:
                  trackSelected === item.id
                    ? theme.palette.primary.contrastText
                    : "none",
              }}
              onClick={() => {
                item.id === trackSelected
                  ? setTrackSelected(null)
                  : setTrackSelected(item.id);
              }}
            >
              {/* <LightTooltip title=> */}
              <LightTooltip
                title={
                  <TooltipContent>
                    <div>
                      <strong>{item.name}</strong>
                    </div>
                    <div>{item.views}</div>
                  </TooltipContent>
                }
              >
                <ImageTextContainer>
                  <ImageContainer>
                    <Rettrive
                      thumbnail_url={item?.url}
                      release_id={item?.release_id}
                      current_page={"dashboard"}
                    />
                  </ImageContainer>
                  <Typography
                    fontWeight={"bold"}
                    sx={{
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.name}
                  </Typography>
                </ImageTextContainer>
              </LightTooltip>
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  };

  const DevicesReport = () => {
    const nameLabel = TopDevicesrows.map((item) => item.name);
    const seriesData = TopDevicesrows.map((item, index) => {
      const itemColor =
        index < theme.palette.dashboardCharts.length
          ? theme.palette.dashboardCharts[index]
          : getRandomColor();
      return {
        value: item.count,
        percentage: item.percent,
        itemStyle: {
          color: itemColor,
        },
      };
    });

    const getOptions = () => {
      return {
        tooltip: {
          trigger: "axis", // or 'item' if you want tooltips for individual bars
          axisPointer: {
            type: "shadow", // This option adds a shadow to the xAxis or yAxis pointer for better visualization
          },
          formatter: (params) => {
            let val = params[0].value;
            let formattedVal = formatValue(val);
            let percentage = params[0].data.percentage.toFixed(2);
            const colorSpan = `<span style="display:inline-block;width:10px;height:10px;margin-right:5px;background-color:${params[0].color};border-radius:50%;"></span>`;
            return `${colorSpan}${params[0].name}:<br>${formattedVal} (${percentage}%)`;
          },
        },
        grid: {
          top: "5%",
          left: "10%",
          right: "5%",
          bottom: "5%",
        },
        dataZoom: [
          {
            type: "inside",
            filterMode: "weakFilter",
          },
        ],
        yAxis: [
          {
            type: "category",
            data: nameLabel.reverse(),
            axisLabel: detailReportOpen
              ? {
                  rotate: 0, // Rotate labels by 45 degrees
                  hideOverlap: true,
                  width: 50, // Set a maximum width for the labels
                  overflow: "break", // Break long labels
                }
              : null,
            name: detailReportOpen ? "Device Type" : "",
            nameLocation: "middle",
            nameTextStyle: {
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontFamily: "BookAntiqua",
              lineHeight: 25,
              fontSize: 20,
              padding: [0, 0, 50, 0],
            },
          },
        ],
        xAxis: [
          {
            type: "value",

            axisLabel: {
              formatter: (value) => formatValue(value),
              hideOverlap: true,
            },
            axisPointer: {
              triggerTooltip: true,
            },
            name: detailReportOpen ? "Sum Of Quantity" : "",
            nameLocation: "middle",
            nameTextStyle: {
              color: theme.palette.primary.main,
              fontWeight: "bold",
              fontFamily: "BookAntiqua",
              fontSize: 20,
              lineHeight: 0,
              padding: [25, 0, 0, 0],
            },
          },
        ],
        series: [
          {
            data: seriesData.reverse(),
            type: "bar",
            barWidth: 15,

            itemStyle: {
              borderRadius: [0, 8, 8, 0],
            },
          },
        ],
      };
    };

    return (
      <Box sx={{ height: "100%" }}>
        <ReactEcharts
          style={{
            height: "100%",
            width: "90%",
          }}
          option={getOptions()}
        />
      </Box>
    );
  };

  function returnReport(reportId) {
    if (reportId === "top_audiostreams_report") return <AudioStreamsChart />;
    else if (reportId === "top_device_report") return <DevicesReport />;
    else if (reportId === "top_track_report") return <TopTrack />;
    else if (reportId === "top_retailers_report") return <RetailerReport />;
    else if (reportId === "top_label_report") return <LabelReport />;
    else return <TerritoryReport />;
  }

  function returnTrackName(trackId) {
    if (props.topTrack_with_date_filter?.Top_tracks_for_given_dates) {
      return props.topTrack_with_date_filter?.Top_tracks_for_given_dates?.filter(
        (track) => track._id === trackId
      )[0]._source.title;
    }
  }

  function returnLabelName(labelId) {
    if (props.topLabel) {
      return (
        props.topLabel.top_labels &&
        props.topLabel.top_labels.length > 0 &&
        props.topLabel.top_labels.filter(
          (item, index) => item.key === labelId
        )[0].label_name
      );
    }
  }
  return (
    <Box>
      {(trackSelected ||
        retailerSelected ||
        labelSelected ||
        countrySelected ||
        devicesSelected ||
        StartDate ||
        EndDate) && (
        <Box
          sx={{
            backgroundColor: theme.palette.background.light,
            borderBottom: `1px solid ${theme.palette.primary.main}`,
            paddingY: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              marginLeft: "1%",
            }}
          >
            <Typography>Applied Filters:</Typography>

            {trackSelected && (
              <Chip
                label={returnTrackName(trackSelected)}
                variant="outlined"
                size="small"
                onDelete={() => {
                  setTrackSelected(null);
                }}
              />
            )}

            {retailerSelected && (
              <Chip
                label={retailerSelected}
                variant="outlined"
                size="small"
                onDelete={() => {
                  setRetailerSelected(null);
                }}
              />
            )}

            {labelSelected && (
              <Chip
                label={returnLabelName(labelSelected)}
                variant="outlined"
                size="small"
                onDelete={() => {
                  setLabelSelected(null);
                }}
              />
            )}

            {countrySelected && (
              <Chip
                label={countrySelected}
                variant="outlined"
                size="small"
                onDelete={() => {
                  setCountrySelected(null);
                }}
              />
            )}

            {devicesSelected && (
              <Chip
                label={devicesSelected}
                variant="outlined"
                size="small"
                onDelete={() => {
                  // setDevicesSelected(null);
                }}
              />
            )}

            {(StartDate || EndDate) && (
              <Chip
                label={`${StartDate} - ${EndDate}`}
                variant="outlined"
                size="small"
                onDelete={() => {
                  setStartDate(formattedThirtyDaysAgoDate);
                  setEndDate(formattedDate);
                }}
              />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginRight: "2%",
            }}
          >
            <CalendarPopover
              setStartDate={setStartDate}
              setEndDate={setEndDate}
              setCountrySelected={setCountrySelected}
              setRetailerSelected={setRetailerSelected}
              setLabelSelected={setLabelSelected}
              setTrackSelected={setTrackSelected}
            />
          </Box>
        </Box>
      )}

      <Grid container spacing={2}>
        <Grid item lg={4} md={6} xs={12} sx={{ height: "43vh" }}>
          <ReportContainer
            className="scrollable"
            name={"Tracks"}
            id={"top_track_report"}
            setDetailReportOpen={setDetailReportOpen}
            setDetailReportId={setDetailReportId}
            overflowY={true}
          >
            {!topTracksRequesting ? (
              <ErrorBoundary message="Error in loading report">
                <TopTrack />
              </ErrorBoundary>
            ) : (
              <LoadingIndicator isActive={topTracksRequesting}>
                <Box
                  sx={{
                    width: "100%",
                    height: "40vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingBgImage width={"50%"} />
                </Box>
              </LoadingIndicator>
            )}
          </ReportContainer>
        </Grid>

        <Grid item lg={4} md={6} xs={12} sx={{ height: "43vh" }}>
          <ReportContainer
            name={"Retailers"}
            id={"top_retailers_report"}
            setDetailReportOpen={setDetailReportOpen}
            setDetailReportId={setDetailReportId}
          >
            {!topStoreRequesting ? (
              <ErrorBoundary message="Error in loading report">
                <RetailerReport />
              </ErrorBoundary>
            ) : (
              <LoadingIndicator isActive={topStoreRequesting}>
                <Box
                  sx={{
                    width: "100%",
                    height: "40vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingBgImage width={"50%"} />
                </Box>
              </LoadingIndicator>
            )}
          </ReportContainer>
        </Grid>

        <Grid item lg={4} md={6} xs={12} sx={{ height: "43vh" }}>
          <ReportContainer
            name={"Labels"}
            id={"top_label_report"}
            setDetailReportOpen={setDetailReportOpen}
            setDetailReportId={setDetailReportId}
          >
            {!topLabelRequesting ? (
              <ErrorBoundary message="Error in loading report">
                <LabelReport />
              </ErrorBoundary>
            ) : (
              <LoadingIndicator isActive={topLabelRequesting}>
                <Box
                  sx={{
                    width: "100%",
                    height: "40vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingBgImage width={"50%"} />
                </Box>
              </LoadingIndicator>
            )}
          </ReportContainer>
        </Grid>

        {/* Second Row */}
        <Grid item lg={4} md={6} xs={12} sx={{ height: "43vh" }}>
          <ReportContainer
            name={"Territory"}
            id={"top_territories_report"}
            setDetailReportOpen={setDetailReportOpen}
            setDetailReportId={setDetailReportId}
          >
            {!topTerritoriesRequesting ? (
              <ErrorBoundary message="Error in loading report">
                <TerritoryReport />
              </ErrorBoundary>
            ) : (
              <LoadingIndicator isActive={topTerritoriesRequesting}>
                <Box
                  sx={{
                    width: "100%",
                    height: "40vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingBgImage width={"50%"} />
                </Box>
              </LoadingIndicator>
            )}
          </ReportContainer>
        </Grid>

        <Grid item lg={4} md={6} xs={12} sx={{ height: "43vh" }}>
          <ReportContainer
            name={"Device Type"}
            id={"top_device_report"}
            setDetailReportOpen={setDetailReportOpen}
            setDetailReportId={setDetailReportId}
          >
            {!topDevicesRequesting ? (
              <ErrorBoundary message="Error in loading report">
                <DevicesReport />
              </ErrorBoundary>
            ) : (
              <LoadingIndicator isActive={topDevicesRequesting}>
                <Box
                  sx={{
                    width: "100%",
                    height: "40vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingBgImage width={"50%"} />
                </Box>
              </LoadingIndicator>
            )}
          </ReportContainer>
        </Grid>

        <Grid item lg={4} md={6} xs={12} sx={{ height: "43vh" }}>
          <ReportContainer
            name={"Audio Streams"}
            id={"top_audiostreams_report"}
            setDetailReportOpen={setDetailReportOpen}
            setDetailReportId={setDetailReportId}
          >
            {!audioStreamsRequesting ? (
              <ErrorBoundary message="Error in loading report">
                <AudioStreamsChart />
              </ErrorBoundary>
            ) : (
              <LoadingIndicator isActive={audioStreamsRequesting}>
                <Box
                  sx={{
                    width: "100%",
                    height: "40vh",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <LoadingBgImage width={"50%"} />
                </Box>
              </LoadingIndicator>
            )}
          </ReportContainer>
        </Grid>
      </Grid>

      <Dialog
        open={detailReportOpen}
        onClose={() => {
          setDetailReportOpen(false);
        }}
        fullWidth
        maxWidth="lg"
      >
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h6" component="h2">
              {returnReportname(detailReportId)}
            </Typography>

            <IconButton
              onClick={() => {
                setDetailReportOpen(false);
              }}
              title="Close"
            >
              <HighlightOffOutlinedIcon sx={{ fontSize: "36px" }} />
            </IconButton>
          </Box>
          <Box
            sx={{
              width: "100%",
              aspectRatio: "1/1",
            }}
          >
            {returnReport(detailReportId)}
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
export default connect(mapStateToProps)(DynamicReport);
