import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Alert,
  Box,
  Grid,
  IconButton,
  Typography,
  Tooltip,
  Paper,
  DialogTitle,
  OutlinedInput,
} from "@mui/material";
import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { faPenToSquare, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddIcon from "@mui/icons-material/Add";
import TrackAction from "../../stores/track/TrackAction";
import ArtistAction from "../../stores/artist/ArtistAction";
import ClientAction from "../../stores/client/ClientAction";
import LanguageAction from "../../stores/language/LanguageAction";
import GenreAction from "../../stores/genre/GenreAction";
import SubGenreAction from "../../stores/subgenre/SubGenreAction";
import { selectRequesting } from "../../selectors/requesting/RequestingSelector";
import ReleasesAction from "../../stores/releases/ReleasesAction";
import { theme } from "../App";
import TrackPost from "../track/TrackPost";
import LoadingIndicator from "../../components/loading-indicator/LoadingIndicator";
import DataGridFunction from "../../components/datagridComponent/datagrid";
import { formatName } from "../../components/reUsedFuntions/ReUsedFunctions";
import BreadCrumbs from "./BreadCrumbs";
import PlanAction from "../../stores/plan/PlanAction";
import { useLocation } from "react-router";
import ActorAction from "../../stores/actor/ActorAction";
import ActressAction from "../../stores/actress/ActressAction";
import DirectorAction from "../../stores/director/DirectorAction";
import MuiButton from "../../components/muiButton/MuiButton";
import { useNavigate } from "react-router";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import LyricsIcon from "@mui/icons-material/Lyrics";
import Zoom from "@mui/material/Zoom";
import AutoCompleteComponent from "../../components/autoCompleteComponent/Autocomplete";

const mapStateToProps = (state) => ({
  isRequesting: selectRequesting(state, [
    TrackAction.REQUEST_TRACK,
    ArtistAction.REQUEST_ARTIST_FILTER,
    ClientAction.REQUEST_CLIENT_FILTER,
    LanguageAction.REQUEST_LANGUAGE,
    GenreAction.REQUEST_GENRE_FILTER,
    SubGenreAction.REQUEST_SUBGENRE_FILTER,
    ReleasesAction.REQUEST_RELEASE_BY_ID,
    PlanAction.REQUEST_PLAN,
    ActorAction.REQUEST_ACTOR_FILTER,
    ActressAction.REQUEST_ACTRESS_FILTER,
    DirectorAction.REQUEST_DIRECTOR_FILTER,
  ]),
  existingTracksRequesting: selectRequesting(state, [
    TrackAction.REQUEST_TRACK_FILTER,
    ReleasesAction.REQUEST_RELEASE_EXISTINGTRACKS,
  ]),
  track: state.track.track.hits || [],
  trackFilter: state.trackFilter.trackFilter.hits || [],
  artistFilter: state.artistFilter.artistFilter || [],
  clientFilter: state.clientFilter.clientFilter || [],
  language: state.language.language || [],
  genreFilter: state.genreFilter.genreFilter || [],
  subgenreFilter: state.subgenreFilter.subgenreFilter || [],
  releaseById: state.releaseById.releaseById || {},
  releaseById2: state.releaseById2.releaseById2 || {},
  plan: state.plan.plan || {},
  actorFilter: state.actorFilter.actorFilter || [],
  actressFilter: state.actressFilter.actressFilter || [],
  directorFilter: state.directorFilter.directorFilter || [],
});

function Tracks(props) {
  const [openTrackDialog, setOpenTrackDialog] = useState(false);
  const [edit, setEdit] = useState(false);
  const [editRowsModel, setEditRowsModel] = useState({});
  const [confirmDialog, setConfirmDialog] = useState({ callingBlock: null });
  const [existingAddLoading, setExistingAddLoading] = useState(false);
  const [bulkUpdateLoading, setBulkUpdateLoading] = useState(false);

  const boxRef = useRef(null);

  const [existingTracksDialog, setExistingTracksDialog] = useState(false);
  const [existingTrack, setExistingTrack] = useState(null);
  const [initialStart, setInitialStart] = useState(true);

  const location = useLocation();
  const navigate = useNavigate();

  function fetchTracks(params) {
    props.dispatch(TrackAction.requestTrack(params)).then((event) => {
      setInitialStart(false);
    });
  }

  function fetchArtistFilter(param) {
    props.dispatch(ArtistAction.requestArtistFilter(param));
  }
  function fetchClientFilter(param) {
    props.dispatch(ClientAction.requestClientFilter(param));
  }

  function fetchlanguageFilter(param) {
    props.dispatch(LanguageAction.requestLanguageFilter(param));
  }

  function fetchGenreFilter(param) {
    props.dispatch(GenreAction.requestGenreFilter(param));
  }
  function fetchSubgenreFilter(param) {
    props.dispatch(SubGenreAction.requestSubgenreFilter(param));
  }

  function fetchResource(resourceId) {
    let params = {
      current_page: "tracks",
    };
    props.dispatch(ReleasesAction.requestReleaseById(resourceId, params));
  }

  function fetchPlan(params) {
    props.dispatch(PlanAction.requestPlan(params));
  }

  function fetchActorFilter(param) {
    props.dispatch(ActorAction.requestActorFilter(param));
  }

  function fetchActressFilter(param) {
    props.dispatch(ActressAction.requestActressFilter(param));
  }
  function fetchDirectorFilter(param) {
    props.dispatch(DirectorAction.requestDirectorFilter(param));
  }

  let genreList = [];
  if (props.genreFilter.hits) {
    props.genreFilter.hits.hits.forEach((value) => {
      genreList.push({
        id: value._id,
        name: value._source.genre_name,
      });
    });
  }

  const [selectedgenre, setSelectedGenre] = useState(null);
  let subgenrelist = [];

  if (props.subgenreFilter.hits) {
    props.subgenreFilter.hits.hits
      .filter((item) => item._source.genre?.id === parseInt(selectedgenre))
      .map((value) => {
        subgenrelist.push({
          id: value._id,
          name: value._source.subgenre_name,
          genre: value._source.genre,
        });
      });
  }

  let AssetAndTrackList = [];

  props.releaseById?._source?.tracks?.map((value) => {
    AssetAndTrackList.push({
      assets_id: value.assets_id ?? "",
      track_id: value.track_id.id ?? "",
    });
  });

  let plan = props.releaseById?._source?.plan_id;
  let primary_artist = props.releaseById?._source?.artists;
  let featuring_artists = props.releaseById?._source?.featuring_artists;

  let artistArray = [];

  if (props.artistFilter.hits) {
    props.artistFilter.hits.hits.forEach((value) => {
      artistArray.push({
        artist_id: value._id,
        artist_name: value._source.artist_name,
        stream_platforms: value._source.stream_platforms,
      });
    });
  }

  let client_name_array = [];

  if (props.clientFilter.hits) {
    props.clientFilter.hits.hits.length !== 0 &&
      props.clientFilter.hits.hits.map((item) => {
        client_name_array.push({
          client_id: item._id,
          client_name: item._source.client_name,
        });
      });
  }

  const getclientnamefunction = (client_id) => {
    let client_name = [];

    let client =
      client_name_array &&
      client_name_array.length !== 0 &&
      client_name_array.filter((val) => {
        return val.client_id === client_id;
      });

    client &&
      client.length !== 0 &&
      client.map((item) => {
        client_name.push(item.client_name);
      });

    return client_name && client_name.length !== 0 ? client_name[0] : "";
  };

  const getclientObjectfunction = (client_id) => {
    let client_name = [];

    let client =
      client_name_array &&
      client_name_array.length !== 0 &&
      client_name_array.filter((val) => {
        return val.client_id === client_id;
      });

    client &&
      client.length !== 0 &&
      client.map((item) => {
        client_name.push({
          client_id: item.client_id,
          client_name: item.client_name,
        });
      });

    return client_name && client_name.length !== 0 ? client_name[0] : "";
  };

  // let languagelist = [];

  // // console.log("new", props.language); // Check the value of props.language
  // // console.log("very", props.language.list_of_language_data);

  // if (
  //   props.language &&
  //   typeof props.language.list_of_language_data === "object"
  // ) {
  //   for (let key in props.language.list_of_language_data) {
  //     console.log("lan", props.language.list_of_language_data[key]);
  //   }
  // }

  let nameslist = [];

  if (
    props.language &&
    typeof props.language.list_of_language_data === "object"
  ) {
    for (let key in props.language.list_of_language_data) {
      const item = props.language.list_of_language_data[key];
      // const name = item.name;
      nameslist.push({ id: key, name: item.name });
    }
  }

  let actorarray = [];
  if (props.actorFilter.hits) {
    props.actorFilter.hits.hits.forEach((value) => {
      actorarray.push({
        actor_id: value._id,
        actor_name: value._source.actor_name,
      });
    });
  }
  let actressarray = [];
  if (props.actressFilter.hits) {
    props.actressFilter.hits.hits.forEach((value) => {
      actressarray.push({
        actress_id: value._id,
        actress_name: value._source.actress_name,
      });
    });
  }

  let directorarray = [];
  if (props.directorFilter.hits) {
    props.directorFilter.hits.hits.forEach((value) => {
      directorarray.push({
        director_id: value._id,
        director_name: value._source.director_name,
      });
    });
  }

  useEffect(() => {
    const params = {
      order_by: `[["track_number","asc"]]`,
      page_limit: "none",
      current_page: "tracks",
      filters: `[["release_id.keyword","must","","match","${localStorage.getItem(
        "id"
      )}"]]`,
    };

    const filterparam = {
      page_limit: "none",
      order_by: `[["created_on","desc"]]`,
      current_page: "tracks",
    };
    let id = localStorage.getItem("id");

    setBulkUpdateLoading(false);
    fetchTracks(params);
    fetchArtistFilter(filterparam);
    fetchClientFilter(filterparam);
    fetchlanguageFilter(filterparam);
    fetchGenreFilter(filterparam);
    fetchSubgenreFilter(filterparam);
    fetchActorFilter(filterparam);
    fetchActressFilter(filterparam);
    fetchDirectorFilter(filterparam);
    fetchResource(id);
    fetchPlan(filterparam);
  }, []);

  const [displayErrorMessage, setDisplayErrorMessage] = useState(false);
  const [submissionRedirect, setSubmissionRedirect] = useState(false);
  const [isTrackError, setIsTrackError] = useState(false);

  useEffect(() => {
    const shouldSubmit = location.state?.shouldSubmit;

    if (shouldSubmit) {
      setDisplayErrorMessage(true);
      setSubmissionRedirect(true);
    }
  }, [!props.isRequesting && location.state?.shouldSubmit]);

  useEffect(() => {
    const trackError = location.state?.trackError;

    if (trackError) {
      setOpenTrackDialog(true);
      setEdit(true);
      setEditRowsModel(location.state.trackData);
      setIsTrackError(true);
    }
  }, [!props.isRequesting && location.state?.trackError]);

  const { _id, _source } = props?.releaseById && props?.releaseById;

  let rows = [];

  const returnLyricsObj = (id) => {
    return nameslist &&
      nameslist.length !== 0 &&
      nameslist.filter((item) => {
        return item.id === id;
      })[0]
      ? nameslist &&
          nameslist.length !== 0 &&
          nameslist.filter((item) => {
            return item.id === id;
          })[0]
      : null;
  };

  if (props.track.hits) {
    props.track.hits.map((item, index) => {
      return rows.push({
        sl_no: index + 1,
        id: item._id,
        _index: item._index,
        arranger: item._source.arranger,
        ask_to_generate_an_isrc: item._source.ask_to_generate_an_isrc,
        author: item._source.author,
        client_id: getclientnamefunction(item._source.client_id),
        client_id_object: getclientObjectfunction(item._source.client_id),
        composer: item._source.composer,
        content_type: item._source.content_type,
        created_by:
          item._source.created_by && item._source.created_by.user_name,
        created_on: item._source.created_on,
        featuring: item._source.featuring,
        genre: item._source.genre,
        director: item._source.director,
        primary_actor: item._source.primary_actor,
        primary_actress: item._source.primary_actress,
        instrumental: item._source.instrumental,
        isrc: item._source.isrc,
        lyrics: item._source.lyrics,
        lyrics_language: item._source?.lyrics_language
          ? item._source.lyrics_language
          : null,
        parental_advisory: item._source.parental_advisory,
        plan_id: item._source.plan_id ? item._source.plan_id : plan ? plan : "",
        preview_start: item._source.preview_start,
        primary_artists: item._source.primary_artists,
        primary_track_type: item._source.primary_track_type,
        producer: item._source.producer,
        producer_catalouge_number: item._source.producer_catalouge_number,
        production_year: item._source.production_year,
        published_by: item._source.published_by,
        publisher: item._source.publisher,
        remixer: item._source.remixer,
        release_id: item._source.release_id,
        secondary_genre: item._source.secondary_genre,
        secondary_track_type: item._source.secondary_track_type,
        seconday_sub_genre: item._source.seconday_sub_genre,
        stream_id: item._source.stream_id,
        sub_genre_name: item._source.sub_genre_name,
        title: item._source.title,
        track_title_language: item._source?.track_title_language
          ? item._source.track_title_language
          : null,
        version_subtitle: item._source["music/album"],
        views: item._source.views,
        phonographic_copyright_year: item._source.phonographic_copyright_year,
        copyright_year: item._source.copyright_year,
        copyrighted_to: item._source.copyrighted_to,
        track_number: item._source.track_number,
      });
    });
  }

  function getAssetId(trackId) {
    let AssetID = "";

    AssetID = AssetAndTrackList.filter((item) => item.track_id === trackId).map(
      (value) => {
        return value.assets_id?.id;
      }
    );

    return AssetID[0] ?? "";
  }

  const callBack = (res) => {
    const tracks = [];
    res?.hits?.hits?.length > 0 &&
      res.hits.hits.map((track) => {
        tracks.push({
          assets_id: getAssetId(track._id),
          track_id: track._id,
        });
      });

    let put_obj = {
      tracks: tracks,
    };

    if (res?.hits?.hits) {
      let params = { current_page: "tracks" };
      props
        .dispatch(
          ReleasesAction.requestPutRelease(
            JSON.stringify(put_obj),
            localStorage.getItem("id"),
            params
          )
        )
        .then(() => {
          props.dispatch(
            ReleasesAction.requestReleaseById(
              localStorage.getItem("id"),
              params
            )
          );
        });
    }
  };

  let planList = [];

  if (props.plan && props.plan.hits) {
    props.plan.hits.hits &&
      props.plan.hits.hits.length > 0 &&
      props.plan.hits.hits.map((plan) => {
        planList.push({
          id: plan._id,
          name: plan._source.plan_name,
        });
      });
  }

  planList = planList.filter((plan) => /T1|T2|T3/.test(plan.name));

  const columns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
      flex: 1,
    },
    {
      field: "title",
      width: 150,
      headerName: "Track Name",
      flex: 7,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Edit",
      width: 160,
      hide: false,
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          disabled={
            _source
              ? _source.status &&
                ["1", "4", "10", "9"].includes(_source.status.id)
                ? false
                : true
              : false
          }
          title="Edit"
          onClick={(event) => {
            event.ignore = true;
            setEdit(true);
            setOpenTrackDialog(true);
            setEditRowsModel(params.row);
          }}
          sx={{ border: "0" }}
        >
          <FontAwesomeIcon
            icon={faPenToSquare}
            style={{
              height: "20px",
              width: "20px",
              color: `${
                _source &&
                _source.status &&
                ["1", "4", "10", "9"].includes(_source.status.id)
                  ? "#2a5e6c"
                  : theme.palette.text.gray
              }`,
            }}
            title="Edit"
          ></FontAwesomeIcon>
        </IconButton>
      ),
    },
    {
      field: "delete",
      type: "actions",
      headerName: "Delete",
      width: 160,
      hide: false,
      flex: 2,
      cellClassName: "actions",
      renderCell: (params) => (
        <IconButton
          title="Delete Track"
          disabled={
            _source
              ? _source.status &&
                ["1", "4", "10", "9"].includes(_source.status.id)
                ? false
                : true
              : false
          }
          onClick={(event) => {
            setConfirmDialog({ callingBlock: "sameReleaseTrack" });
            event.ignore = true;
            setEditRowsModel(params.row);
          }}
          sx={{ border: "0" }}
        >
          {
            <FontAwesomeIcon
              icon={faTrash}
              style={{
                height: "20px",
                width: "20px",
                color: `${
                  _source &&
                  _source.status &&
                  ["1", "4", "10", "9"].includes(_source.status.id)
                    ? "#2a5e6c"
                    : theme.palette.text.gray
                }`,
              }}
              title="Edit"
            ></FontAwesomeIcon>
          }
        </IconButton>
      ),
    },
  ];

  const returnTrackAddition = (input) => {
    let boolean = false;

    if (
      input === "single"
      // &&
      // props.track &&
      // props.track.hits &&
      // props.track.hits.length < 1
    ) {
      boolean = true;
    }

    if (
      input === "EP" &&
      props.track &&
      props.track.hits &&
      props.track.hits.length < 5
    ) {
      boolean = true;
    }

    if (input === "album") {
      boolean = true;
    }

    return boolean;
  };

  function deleteFile() {
    const deleteCallBack = (deleteResponse) => {
      if (deleteResponse?.status === "Deleted") {
        let remainingRows = props.track.hits.filter(
          (row) => row._id !== deleteResponse._id
        );
        updateTrackOrderNumber(remainingRows);
      }
    };

    props
      .dispatch(
        TrackAction.requestDeleteTrack(editRowsModel.id, deleteCallBack)
      )
      .then(() => {
        setConfirmDialog({ callingBlock: null });
        const params = {
          order_by: `[["track_number","asc"]]`,
          page_limit: "none",
          current_page: "tracks",
          filters: `[["release_id.keyword","must","","match","${localStorage.getItem(
            "id"
          )}"]]`,
        };
        props.dispatch(TrackAction.requestTrack(params, callBack));
      });
    setEditRowsModel({});
  }

  //Existing Tracks Section
  useEffect(() => {
    if (existingTracksDialog && props.releaseById?._source?.label) {
      let label = props.releaseById._source.label;
      const filter = [];
      filter.push(`["label.keyword","must","","match","${label.id}"]`);
      const params = {
        filters: `[${filter}]`,
        page_limit: "none",
        order_by: '[["title.keyword", "asc"]]',
      };
      props.dispatch(TrackAction.requestTrackFilter(params));
    }
  }, [existingTracksDialog]);

  let existingTrackList = [];
  if (props.trackFilter?.hits) {
    props.trackFilter.hits.forEach((value) => {
      existingTrackList.push({
        track_id: value._id,
        name: value._source.title,
        release_id: value._source.release_id.id,
      });
    });
  }

  let existingTrackRows = [];
  if (props.releaseById._source?.existing_tracks) {
    props.releaseById._source.existing_tracks.forEach((value, index) => {
      existingTrackRows.push({
        id: index + 1,
        sl_no: index + 1,
        track: value.track_id?.title,
        track_id: value.track_id?.id,
        release: value.release_id?.title,
        asset: value.assets_id?.assets_name,
      });
    });
  }

  const existingTrackColumns = [
    {
      field: "sl_no",
      headerName: "SL NO",
      resizable: false,
      align: "center",
      headerAlign: "center",
    },
    {
      field: "track",
      flex: 2,
      headerName: "Exisiting Track Name",
    },
    {
      field: "release",
      flex: 2,
      headerName: "Release Name",
    },
    {
      field: "asset",
      flex: 2,
      headerName: "Asset Name",
    },
    {
      field: "delete",
      type: "actions",
      headerName: "Delete",
      flex: 1,
      cellClassName: "actions",
      renderCell: (params) => {
        return (
          <IconButton
            title="Delete Track"
            onClick={(event) => {
              setConfirmDialog({ callingBlock: "otherReleaseTrack" });
              setEditRowsModel(params);
            }}
            sx={{ border: "0" }}
          >
            <FontAwesomeIcon
              icon={faTrash}
              style={{ height: "20px", width: "20px", color: "#2a5e6c" }}
              title="Delete"
            ></FontAwesomeIcon>
          </IconButton>
        );
      },
    },
  ];

  function existingTrackHandleChange(e, eTrack) {
    setExistingTrack(eTrack);
  }

  function existingTrackSubmit() {
    if (!existingTrack) return;
    setExistingAddLoading(true);

    const updateTrackCallback = (res) => {
      let releaseResponse = res;

      const assetID = releaseResponse?._source?.tracks.find(
        (track) => track.track_id?.id === existingTrack.track_id
      )?.assets_id?.id;

      let obj = {};
      let existingObjects = props.releaseById._source?.existing_tracks || [];
      let newArray = existingObjects.map((item) => ({
        release_id: item.release_id?.id,
        track_id: item.track_id?.id,
        assets_id: item.assets_id?.id,
      }));

      let newObj = {
        assets_id: assetID,
        track_id: existingTrack.track_id,
        release_id: existingTrack.release_id,
      };
      newArray.push(newObj);
      newArray = newArray.filter(
        (value, index, self) =>
          index ===
          self.findIndex(
            (t) =>
              t.track_id === value.track_id && t.asset_id === value.asset_id
          )
      );

      Object.assign(
        obj,
        newArray.length > 0
          ? { existing_tracks: newArray }
          : { existing_tracks: [] }
      );

      props
        .dispatch(
          ReleasesAction.requestPutRelease(
            obj,
            props.releaseById._id,
            {},
            callBack
          )
        )
        .then(() => {
          fetchResource(props.releaseById._id);
          setExistingTrack(null);
          setExistingAddLoading(false);
          setExistingTracksDialog(false);
        });
    };

    props.dispatch(
      ReleasesAction.requestReleaseExistingTrack(
        existingTrack.release_id,
        {},
        updateTrackCallback
      )
    );
  }

  function deleteExistingTrack() {
    let obj = {};
    let existingObjects = props.releaseById._source?.existing_tracks.map(
      (item) => ({
        release_id: item.release_id?.id,
        track_id: item.track_id?.id,
        assets_id: item.assets_id?.id,
      })
    );
    const filteredArray = existingObjects.filter(
      (item) => item.track_id !== editRowsModel.row.track_id
    );

    Object.assign(
      obj,
      filteredArray.length > 0
        ? { existing_tracks: filteredArray }
        : { existing_tracks: [] }
    );
    props
      .dispatch(
        ReleasesAction.requestPutRelease(
          obj,
          props.releaseById._id,
          {},
          callBack
        )
      )
      .then(() => {
        setConfirmDialog({ callingBlock: null });
        fetchResource(props.releaseById._id);
      });
  }

  const rowOrderHandleChange = async (params) => {
    const newRows = [...rows];
    const selectedItem = newRows.splice(params.oldIndex, 1)[0];
    newRows.splice(params.targetIndex, 0, selectedItem);

    for (let i = 0; i < newRows.length; i++) {
      newRows[i].track_number = i + 1;
      newRows[i].sl_no = i + 1;
    }
    rows = newRows;
  };

  function updateTrackOrderNumber(tracks) {
    const resultArray = tracks.map((track, index) => {
      const matchingRowsObject = rows.find(
        (rowsObj) => rowsObj.id === track._id
      );
      if (matchingRowsObject) {
        const updatedTrack = {
          ...track,
          _source: {
            ...track._source,
            track_number: index + 1,
            client_id: track._source?.client_id?.id,
            plan_id: track._source?.plan_id?.id,
            track_title_language:
              track._source?.track_title_language?.track_title_language,
            sub_genre_name: track._source?.sub_genre_name?.id,
            genre: track._source?.genre?.id,
            release_id: track._source?.release_id?.id,
            lyrics_language: track._source?.lyrics_language?.lyrics_language,
            label: track._source?.label?.id,
            primary_artists: track._source?.primary_artists?.length
              ? track._source?.primary_artists.map((artist) => ({
                  artist_id: artist?.artist_id?.id,
                  artist_name: artist?.artist_id?.artist_name,
                }))
              : [],
            featuring: track._source?.featuring?.length
              ? track._source?.featuring.map((artist) => ({
                  artist_id: artist?.artist_id?.id,
                  artist_name: artist?.artist_id?.artist_name,
                }))
              : [],
            remixer: track._source?.remixer?.length
              ? track._source?.remixer.map((artist) => ({
                  artist_id: artist?.artist_id?.id,
                  artist_name: artist?.artist_id?.artist_name,
                }))
              : [],
          },
        };
        return updatedTrack;
      }
      return track;
    });
    props
      .dispatch(TrackAction.requestPutTrackBulkUpdate({ data: resultArray }))
      .then(() => {
        const params = {
          order_by: `[["track_number","asc"]]`,
          page_limit: "none",
          filters: `[["release_id.keyword","must","","match","${localStorage.getItem(
            "id"
          )}"]]`,
        };
        navigate("/releaseDate");
      });
  }

  return (
    <Box ref={boxRef} paddingLeft={2} paddingRight={2} paddingBottom={2}>
      <Dialog
        open={Boolean(confirmDialog.callingBlock)}
        fullWidth
        maxWidth="sm"
        onClose={() => {
          setConfirmDialog({ callingBlock: null });
        }}
      >
        <DialogContent>
          <div style={{ justifyContent: "center", display: "flex" }}>
            <h5>Are you sure you want to delete the track ?</h5>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            size="small"
            onClick={() => {
              setConfirmDialog({ callingBlock: null });
            }}
          >
            <ClearIcon />
          </Button>
          <Button
            size="small"
            onClick={() => {
              confirmDialog.callingBlock == "sameReleaseTrack" && deleteFile();
              confirmDialog.callingBlock == "otherReleaseTrack" &&
                deleteExistingTrack();
            }}
          >
            <CheckIcon />
          </Button>
        </DialogActions>
      </Dialog>

      <Grid
        item
        container
        xl={12}
        lg={12}
        sm={12}
        md={12}
        alignItems="center"
        justifyContent={"space-between"}
        style={{
          position: "sticky",
          top: `${boxRef?.current?.offsetTop}px`,
          backgroundColor: theme.palette.background.default,
          zIndex: 10,
        }}
      >
        <Grid
          item
          xl={10}
          lg={10}
          md={10}
          sm={10}
          container
          alignItems={"center"}
          justifyContent="flex-start"
          gap={2}
          paddingTop={1}
        >
          <Grid item>
            <h2>{formatName(`${_source?.title}`)}</h2>
          </Grid>

          <Grid item>
            <BreadCrumbs pathname={window.location.pathname} />
          </Grid>
        </Grid>
        <Grid
          item
          lg={12}
          sm={12}
          md={12}
          xl={12}
          className="d-flex justify-content-end"
          sx={{
            paddingTop: "10px",
            paddingX: {
              lg: 20,
              md: 1,
              sm: 1,
            },
          }}
        >
          <Grid item lg={1} md={2} sm={2}>
            <MuiButton
              name={"Next"}
              loading={bulkUpdateLoading}
              onClick={() => {
                setBulkUpdateLoading(true);
                // Create a mapping of IDs to their order in the first array
                const idOrderMap = {};
                rows.forEach((item, index) => {
                  idOrderMap[item.id] = index;
                });
                // Sorting function
                const customSort = (item) => {
                  return idOrderMap[item._id];
                };
                // Sort the second array based on the order of IDs in the first array
                const sortedArray = props.track.hits
                  ?.slice()
                  .sort((a, b) => customSort(a) - customSort(b));

                updateTrackOrderNumber(sortedArray);
              }}
              size="small"
              width={"100%"}
            />
          </Grid>
        </Grid>
      </Grid>

      {displayErrorMessage &&
        props.track.hits &&
        props.track.hits.length === 0 && (
          <Alert severity="error">Atleast One Track is required!</Alert>
        )}

      {_source?.format === null && (
        <Alert severity="error">
          Please Select the format in Release information to add tracks.
        </Alert>
      )}

      <Grid
        container
        sx={{
          display: "flex",
          flexWrap: "wrap",
          justifyContent: "center",
          gap: 5,
        }}
      >
        {/* <Grid
          item
          sx={{
            display: "flex",
            flexDirection: "column",
            color: theme.palette.background.primary,
          }}
        >
          <Grid
            item
            sx={{
              width: { xl: "240px", lg: "240px" },
              height: { xl: "192px", lg: "192px" },
              backgroundColor: theme.palette.background.paper,
              border: `1px dashed ${theme.palette.borderColor.uploadButtons}`,
              mb: "10px",
            }}
          >
            <Typography
              component={"label"}
              htmlFor="asset-dolbyaudio-upload"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                width: "100%",
                height: "100%",
                cursor: "pointer",
              }}
            >
              <Typography
                id="asset-dolbyaudio-upload"
                component={"input"}
                // type="file"
                //multiple
                accept=" audio/*"
                // onChange={handleFileUpload}
                sx={{ display: "none" }}
              ></Typography>
              <LibraryMusicIcon
                sx={{ fontSize: "40px", color: theme.palette.primary.light }}
              />
              <Typography
                component={"span"}
                sx={{
                  fontSize: "16px",
                  mt: "15px",
                }}
              >
                Upload Dolby Audio
              </Typography>
            </Typography>
          </Grid>
        </Grid> */}
        {_source &&
          _source.format &&
          _source.status &&
          ["1", "4", "10", "9"].includes(_source.status.id) &&
          returnTrackAddition(_source.format) && (
            <Grid
              item
              sx={{
                display: "flex",
                flexDirection: "column",
                color: theme.palette.background.primary,
              }}
            >
              <Grid
                item
                sx={{
                  width: {
                    xl: "240px",
                    lg: "240px",
                    md: "200px",
                    sm: "150px",
                    xs: "150px",
                  },
                  height: {
                    xl: "192px",
                    lg: "240px",
                    md: "200px",
                    sm: "150px",
                    xs: "150px",
                  },
                  backgroundColor: theme.palette.background.paper,
                  border: `1px dashed ${theme.palette.borderColor.uploadButtons}`,
                  mb: "10px",
                }}
              >
                <Typography
                  component={"label"}
                  htmlFor="asset-video-upload"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                    height: "100%",
                    cursor: "pointer",
                  }}
                >
                  <Typography
                    id="asset-video-upload"
                    component={"input"}
                    // type="file"
                    //multiple
                    accept=" video/*"
                    // onChange={handleFileUpload}
                    sx={{ display: "none" }}
                    onClick={() => {
                      setOpenTrackDialog(true);
                      setEdit(false);
                    }}
                  ></Typography>
                  <AddIcon
                    sx={{
                      fontSize: "40px",
                      color: theme.palette.primary.light,
                    }}
                  />
                  <Typography
                    component={"span"}
                    sx={{
                      fontSize: "16px",
                      mt: "15px",
                    }}
                  >
                    Add Track Details
                  </Typography>
                </Typography>
              </Grid>
            </Grid>
          )}
      </Grid>

      {navigator.onLine ? (
        <Box>
          <Grid
            display="flex"
            justifyContent="center"
            container
            item
            lg={12}
            md={12}
            sm={12}
          >
            <Grid item lg={8} md={12} sm={12} xs={12}>
              <LoadingIndicator
                isActive={props.isRequesting || bulkUpdateLoading}
              >
                {props.track.hits ? (
                  <DataGridFunction
                    columns={columns}
                    rows={!initialStart && !bulkUpdateLoading ? rows : []}
                    isRequesting={
                      initialStart || bulkUpdateLoading || props.isRequesting
                    }
                    rowHeight={60}
                    height={rows.length * 60 + 50}
                    rowReordering
                    onRowOrderChange={rowOrderHandleChange}
                  />
                ) : null}
              </LoadingIndicator>
            </Grid>

            <TrackPost
              plan={plan}
              primary_artist={primary_artist}
              featuring_artists={featuring_artists}
              isTrackError={isTrackError}
              submissionRedirect={submissionRedirect}
              releasedata={_source ? _source : null}
              returnLyricsObj={returnLyricsObj}
              isRequesting={props.isRequesting}
              open={openTrackDialog}
              onSubmit={() => {
                const params = {
                  order_by: `[["track_number","asc"]]`,
                  page_limit: "none",
                  current_page: "tracks",
                  filters: `[["release_id.keyword","must","","match","${localStorage.getItem(
                    "id"
                  )}"]]`,
                };

                fetchTracks(params);
              }}
              onGet={(name) => {
                let params = {
                  order_by: `[["created_on","desc"]]`,
                  page_limit: "none",
                  current_page: "tracks",
                };

                if (name === "primary_actor") {
                  fetchActorFilter(params);
                } else if (name === "primary_actress") {
                  fetchActressFilter(params);
                } else if (name === "director") {
                  fetchDirectorFilter(params);
                }
              }}
              onClose={() => setOpenTrackDialog(false)}
              projectEditData={editRowsModel}
              edit={edit}
              artistArray={artistArray}
              release_id={localStorage.getItem("id")}
              AssetAndTrackList={AssetAndTrackList}
              nameslist={nameslist}
              subgenrelist={subgenrelist ?? []}
              genreList={genreList ?? []}
              planList={planList}
              actorarray={actorarray}
              actressarray={actressarray}
              directorarray={directorarray}
              setIsTrackError={setIsTrackError}
              setSelectedGenre={setSelectedGenre}
              currentTracksLength={props.track?.hits?.length}
            />
          </Grid>
          <Grid display="flex" justifyContent="center" container item xs={12}>
            <Tooltip
              title="Click to select an already existing tracks"
              arrow
              placement="right"
            >
              <Paper
                elevation={10}
                sx={{
                  cursor: "pointer",
                  width: "10%",
                  marginTop: "20px",
                  padding: "10px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.4)",
                  transition: "box-shadow 0.3s, transform 0.3s",
                  whiteSpace: "nowrap",
                  fontWeight: "bold",
                  paddingX: "100px",
                  "&:hover": {
                    boxShadow: "0px 0px 18px rgba(0, 0, 0, 0.6)",
                    transform: "scale(1.02)",
                  },
                }}
                onClick={() => {
                  setExistingTracksDialog(true);
                }}
              >
                <LyricsIcon />
                &nbsp;&nbsp;Add Existing Tracks
              </Paper>
            </Tooltip>
          </Grid>
          <Grid
            display="flex"
            justifyContent="center"
            container
            item
            sm={12}
            mt={3}
          >
            <Grid item lg={8} xs={12}>
              {existingTrackRows.length > 0 ? (
                // <LoadingIndicator isActive={props.isRequesting}>
                <DataGridFunction
                  columns={existingTrackColumns}
                  rows={existingTrackRows}
                  rowHeight={60}
                  height={existingTrackRows.length * 60 + 50}
                />
              ) : // </LoadingIndicator>
              null}
            </Grid>
          </Grid>
        </Box>
      ) : (
        alert("No Internet Connection") || (
          <h3 style={{ textAlign: "center" }}>No Internet Connection</h3>
        )
      )}
      <Dialog
        open={existingTracksDialog}
        onClose={() => {
          setExistingTracksDialog(false);
          setExistingTrack(null);
        }}
        fullWidth
        maxWidth="sm"
        TransitionComponent={Zoom}
        transitionDuration={300}
      >
        <DialogTitle sx={{ textAlign: "center", fontSize: "32px" }}>
          {formatName("Add Existing Tracks")}
        </DialogTitle>
        <Box sx={{ paddingX: 7 }}>
          <Typography pb={1} sx={{ fontWeight: "bold" }}>
            Label Name
          </Typography>
          <OutlinedInput
            sx={{
              height: "37px",
              width: "100%",
            }}
            value={
              props.releaseById._source?.label
                ? props.releaseById._source.label.label_name
                : "Enter a Label Name to continue"
            }
            disabled
          />
          <Grid
            mt={4}
            pb={1}
            sx={{ fontWeight: "bold" }}
            display="flex"
            gap={1}
          >
            Select Tracks{" "}
            {props.existingTracksRequesting && (
              <CircularProgress color="inherit" size={15} />
            )}
          </Grid>
          <AutoCompleteComponent
            value={existingTrack}
            list={existingTrackList}
            textFieldProps={{
              placeholder: "Track Name",
            }}
            getOptionLabel={(option) => option.name}
            isOptionEqualToValue={(option, value) =>
              option && option.track_id === value && value.track_id
            }
            renderOption={(props, item) => (
              <li {...props} key={item.track_id}>
                {item.name}
              </li>
            )}
            handleChange={existingTrackHandleChange}
            size="small"
            width="100%"
            disabled={props.existingTracksRequesting}
          />
        </Box>
        <DialogActions
          style={{ padding: "16px", marginTop: "20px", paddingRight: "25px" }}
        >
          <MuiButton
            width="30%"
            name={"Add"}
            loading={existingAddLoading}
            onClick={() => {
              existingTrackSubmit();
            }}
          />
          <MuiButton
            cancel
            width="30%"
            name={"Cancel"}
            onClick={() => {
              setExistingTracksDialog(false);
              setExistingTrack(null);
            }}
          />
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default connect(mapStateToProps)(Tracks);
